import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  containersection: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    marginTop: "16px",
  },

  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "600",
  },
  subHeading: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#666666",
  },
  prodimage: {
    height: "44px",
    width: "44px",
    borderRadius: "6px",
  },

  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 44,
    height: 44,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  skippedHeading: {
    fontSize: "12px",
    fontWeight: 400,
    backgroundColor: "#FFEFEF",
    padding: "5px 8px ",
    borderRadius: "20px",
    marginLeft: "12px",
  },
  skippedButton: {
    fontSize: "14px",
    textDecoration: "none",
    padding: "0",
    color: "#1641DB",
    fontWeight: 600,
  },
}));
