import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Menu,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Done,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreVert,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { updateSetting } from "../../../redux/store/storeActions";
import { THEME_COLORS } from "../../../utils/utils";
import useStyles from "../styles";
import fontFamily from "./FontFamily.json";
import WebFont from "webfontloader";
import {
  rowFlexAlignCenter,
  rowFlexCenterAll,
  rowFlexJustifyBetween,
} from "../../../styles/common";
import PaidPopUp from "../../../components/PaidPopUp";
import { BUSINESS_APP_PRO, PLANS } from "../../plans/constants";
import PaidTab from "../../../components/common/PaidTab";
import Dropzone from "react-dropzone";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { uploadFile } from "../../../utils/fileUploader";
import { getStoreIdFromUrl, updateAttributes } from "../../../utils/storeUtil";
import FileDownload from "../../../icons/FileDownload";
import FileRestore from "../../../icons/FileRestore";
import FileUpload from "../../../icons/FileUpload";
import Controls from "../../../components/controls/Controls";
import { borderRadiusForButtonsOprions } from "../settingUtils";
import { fetchAdditionalFields } from "../../../redux/additionalFields/additionalFieldsActions";

const GeneralSetting = ({
  store,
  onClose,
  setToast,
  scrollIndex,
  setScrollIndex,
  selectedColor,
  setSelectedColor,
  selectedFontFamily,
  setSelectedFontFamily,
  attributes,
  setAttributes,
  borderRadiusForButtons,
  setBorderRadiusForButtons,
}) => {
  const classes = useStyles();
  const storeId = getStoreIdFromUrl();

  const { fields } = useSelector((state) => state.additionalFields);

  const [loading, setLoading] = useState(false);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [filename, setFilename] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardSetting, setCardSetting] = useState({});
  const [pageSetting, setPageSetting] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (fields.length === 0) {
      dispatch(fetchAdditionalFields());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: fontFamily,
      },
    });
    // eslint-disable-next-line
  }, [fontFamily]);

  useEffect(() => {
    if (attributes?.site_settings?.css_name) {
      setFilename(attributes.site_settings.css_name);
    }
    setCardSetting(attributes.product_card_settings);
    setPageSetting(attributes.product_page_settings);
  }, [attributes]);

  function hexToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0;

    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];

    return "rgba(" + +r + "," + +g + "," + +b + ",0.24)";
  }

  const handleDrop = (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    setFilename(file.name);
    setUploadedFile(file);
    setUploading(false);
    setAnchorEl(null);
  };

  const reset = () => {
    setAnchorEl(null);
    setFilename("");
    setUploading(false);
    setUploadedFile(null);
  };

  const download = () => {
    const a = document.createElement("a");
    a.href = attributes.site_settings.css_location;
    a.target = "_blank";
    a.download = attributes.site_settings.css_location;
    a.click();
    a.remove();
    setAnchorEl(null);
  };

  const onSave = async () => {
    let atr = { ...attributes };
    setLoading(true);
    if (uploadedFile) {
      const cloudFile = await uploadFile(filename, uploadedFile);
      atr = {
        ...atr,
        site_settings: {
          css_location: cloudFile ? cloudFile.payload.location : null,
          css_name: cloudFile ? filename : null,
        },
      };
      await updateAttributes(store.slug, "site_settings", [
        {
          name: "css_location",
          value: cloudFile.payload.location,
        },
        {
          name: "css_name",
          value: filename,
        },
      ]);
    } else if (!filename) {
      atr = {
        ...atr,
        site_settings: {
          css_location: null,
          css_name: null,
        },
      };

      await updateAttributes(store.slug, "site_settings", [
        {
          name: "css_location",
          value: null,
        },
        {
          name: "css_name",
          value: null,
        },
      ]);
    }
    atr = {
      ...atr,
      product_card_settings: {
        show_addcart_cta: getBoolean(cardSetting?.show_addcart_cta),
        show_whatsapp_cta: getBoolean(cardSetting?.show_whatsapp_cta),
      },
      product_page_settings: {
        show_addcart_cta: getBoolean(pageSetting?.show_addcart_cta),
        show_whatsapp_cta: getBoolean(pageSetting?.show_whatsapp_cta),
        show_buynow_cta: getBoolean(pageSetting?.show_buynow_cta),
      },
    };
    setAttributes(atr);
    await updateAttributes(store.slug, "product_card_settings", [
      {
        name: "show_addcart_cta",
        value: getBoolean(cardSetting?.show_addcart_cta),
      },
      {
        name: "show_whatsapp_cta",
        value: getBoolean(cardSetting?.show_whatsapp_cta),
      },
    ]);
    await updateAttributes(store.slug, "product_page_settings", [
      {
        name: "show_addcart_cta",
        value: getBoolean(pageSetting?.show_addcart_cta),
      },
      {
        name: "show_buynow_cta",
        value: getBoolean(pageSetting?.show_buynow_cta),
      },
      {
        name: "show_whatsapp_cta",
        value: getBoolean(pageSetting?.show_whatsapp_cta),
      },
    ]);
    await updateAttributes(store.slug, "site_settings", [
      {
        name: "border_radius_for_button",
        value: borderRadiusForButtons,
      },
    ]);

    dispatch(
      updateSetting(
        {
          theme_color: selectedColor,
          font_family: selectedFontFamily,
        },
        store.store_setting.id
      )
    ).then(() => {
      setToast(true);
      setLoading(false);
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100%",
        }}
      >
        <div>
          <div className={classes.dividerGrid}>
            <div className={rowFlexJustifyBetween} style={{ width: "100%" }}>
              <div>
                <div className={rowFlexAlignCenter}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginRight: 5,
                    }}
                  >
                    Website CSS
                  </Typography>
                </div>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: "#666666",
                    marginTop: 4,
                  }}
                >
                  Download{" "}
                  <a
                    href="https://storage.googleapis.com/shy-pub/_static/css/shoopy_sample_v6.css"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sample CSS File
                  </a>
                </Typography>
              </div>
              <div className={rowFlexCenterAll}>
                {filename ? (
                  <div className={rowFlexCenterAll}>
                    <Typography
                      style={{ fontSize: 14, fontWeight: 500, height: 24 }}
                    >
                      {filename}&ensp;
                    </Typography>
                    <MoreVert
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}
                    />
                  </div>
                ) : PLANS[store.plan]?.weight <
                  PLANS.BUSINESS_APP_PRO?.weight ? (
                  <OutlinedButton
                    style={{
                      width: "fit-content",
                      height: 24,
                      padding: 0,
                      fontWeight: 400,
                      fontSize: 14,
                      minWidth: 0,
                      border: "none",
                    }}
                    onClick={(e) => {
                      setOpenPremiumModal(true);
                    }}
                  >
                    Upload <PaidTab plan={BUSINESS_APP_PRO} />
                  </OutlinedButton>
                ) : (
                  <Dropzone
                    onDrop={handleDrop}
                    multiple={false}
                    maxSize={5e6}
                    accept=".css"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={{ outline: "none" }}>
                        <input {...getInputProps()} />
                        <OutlinedButton
                          style={{
                            width: "fit-content",
                            height: 24,
                            padding: 0,
                            fontWeight: 400,
                            fontSize: 14,
                            minWidth: 0,
                            border: "none",
                          }}
                          loading={uploading}
                        >
                          Upload
                        </OutlinedButton>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
          <div
            className={classes.dividerGrid}
            style={{ display: "block", paddingTop: 18 }}
          ></div>
          <div
            className={classes.dividerGrid}
            style={{ display: "block", paddingTop: 18 }}
          >
            <Typography
              style={{ color: "#1a1a1a", fontWeight: 600, fontSize: 14 }}
              id="select-label"
            >
              Choose Font
            </Typography>
            <FormControl
              fullWidth
              color="secondary"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Select
                color="secondary"
                fullWidth
                variant="outlined"
                labelId="select-label"
                onChange={(e) => setSelectedFontFamily(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.paper },
                }}
                value={selectedFontFamily}
                renderValue={(selected) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontFamily: `${'"' + selected + '"'}`,
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        {selected}
                      </Typography>
                    </>
                  );
                }}
              >
                {fontFamily.map((font, idx) => (
                  <MenuItem key={idx} value={font}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{ fontSize: 12 }}
                        className={classes.menuText}
                      >
                        {font}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: `${'"' + font + '"'}`,
                        }}
                        className={classes.subMenuText}
                      >
                        {font}
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              style={{
                color: "#1a1a1a",
                fontWeight: 600,
                fontSize: 14,
                marginBottom: 16,
              }}
            >
              Border Radius For Buttons
            </Typography>
            <Controls.Select
              name="product variants"
              value={borderRadiusForButtons}
              onChange={(e) => {
                setBorderRadiusForButtons(e.target.value);
              }}
              options={borderRadiusForButtonsOprions}
              color="secondary"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              labelPlacement="top"
              helperText="Applies to Buttons"
            />
          </div>
          <div className={classes.dividerGrid}>
            <div
              style={{
                width: "110%",
                marginLeft: "-5%",
                marginBottom: 14,
              }}
            >
              <p
                className={classes.subHeading}
                style={{ marginBottom: 20, marginLeft: "5%" }}
              >
                Choose a Theme Color
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginTop: -42,
                    marginLeft: 15,
                    cursor: scrollIndex > 0 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex > 0) setScrollIndex(scrollIndex - 1);
                  }}
                >
                  {scrollIndex > 0 && <KeyboardArrowLeft color="secondary" />}
                  {scrollIndex <= 0 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
                {THEME_COLORS.map((color, i) => {
                  if (i < scrollIndex || i > 4 + scrollIndex) {
                    return <Fragment key={i} />;
                  }

                  return (
                    <div
                      style={{
                        width: 66,
                        textAlign: "-webkit-center",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => setSelectedColor(color.code)}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 28,
                          width: 28,
                          background: color.code,
                          boxShadow: `0px 10px 20px ${hexToRGBA(color.code)}`,
                          border:
                            selectedColor === color.code
                              ? "2px solid white"
                              : "",
                        }}
                      >
                        {selectedColor === color.code && (
                          <Done fontSize="small" color="primary" />
                        )}
                      </div>
                      <p
                        style={{
                          marginTop: 12,
                          fontSize: 12,
                          color: "#666666",
                          fontWeight: selectedColor === color.code ? 600 : 400,
                        }}
                      >
                        {color.name}
                      </p>
                    </div>
                  );
                })}
                <span
                  style={{
                    marginTop: -42,
                    marginRight: 15,
                    cursor:
                      scrollIndex < THEME_COLORS.length - 5
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex < THEME_COLORS.length - 5)
                      setScrollIndex(scrollIndex + 1);
                  }}
                >
                  {scrollIndex < THEME_COLORS.length - 5 && (
                    <KeyboardArrowRight color="secondary" />
                  )}
                  {scrollIndex >= THEME_COLORS.length - 5 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.dividerGrid} style={{ display: "block" }}>
            <p className={classes.subHeading} style={{ marginBottom: 20 }}>
              Product Card
            </p>
            <div
              className={classes.dividerBox}
              style={{
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                className={classes.subHeading}
                style={{ fontWeight: 600, paddingTop: 0, marginRight: 64 }}
              >
                Call to Actions
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBoolean(cardSetting?.show_addcart_cta)}
                    onChange={(e) => {
                      setCardSetting({
                        ...cardSetting,
                        show_addcart_cta: e.target.checked,
                      });
                    }}
                    name="Add to cart"
                    color="secondary"
                  />
                }
                classes={{ label: classes.label }}
                className={classes.controlBox}
                label="Add to cart"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBoolean(cardSetting?.show_whatsapp_cta)}
                    onChange={(e) => {
                      setCardSetting({
                        ...cardSetting,
                        show_whatsapp_cta: e.target.checked,
                      });
                    }}
                    name="WhatsApp"
                    color="secondary"
                  />
                }
                classes={{ label: classes.label }}
                className={classes.controlBox}
                label="WhatsApp"
              />
            </div>
          </div>
          <div className={classes.dividerGrid} style={{ display: "block" }}>
            <p className={classes.subHeading} style={{ marginBottom: 20 }}>
              Product Page
            </p>
            <div
              className={classes.dividerBox}
              style={{
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                className={classes.subHeading}
                style={{ fontWeight: 600, paddingTop: 0, marginRight: 64 }}
              >
                Call to Actions
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBoolean(pageSetting?.show_addcart_cta)}
                    onChange={(e) => {
                      setPageSetting({
                        ...pageSetting,
                        show_addcart_cta: e.target.checked,
                      });
                    }}
                    name="Add to cart"
                    color="secondary"
                  />
                }
                classes={{ label: classes.label }}
                className={classes.controlBox}
                label="Add to cart"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBoolean(pageSetting?.show_buynow_cta)}
                    onChange={(e) => {
                      setPageSetting({
                        ...pageSetting,
                        show_buynow_cta: e.target.checked,
                      });
                    }}
                    name="Buy now"
                    color="secondary"
                  />
                }
                classes={{ label: classes.label }}
                className={classes.controlBox}
                label="Buy now"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getBoolean(pageSetting?.show_whatsapp_cta)}
                    onChange={(e) => {
                      setPageSetting({
                        ...pageSetting,
                        show_whatsapp_cta: e.target.checked,
                      });
                    }}
                    name="WhatsApp"
                    color="secondary"
                  />
                }
                classes={{ label: classes.label }}
                className={classes.controlBox}
                label="WhatsApp"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 50,
          }}
        >
          <CombinedButtons
            outlinedBtnAction={onClose}
            loading={loading}
            solidBtnAction={onSave}
          />
        </div>
      </div>
      <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={BUSINESS_APP_PRO}
        subtxt="Define custom styles using CSS for elements and pages in your web store."
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem style={{ fontSize: 12, color: "#1641DB" }} onClick={download}>
          <FileDownload />
          &ensp;Get current CSS file
        </MenuItem>
        <MenuItem style={{ fontSize: 12, color: "#1641DB" }}>
          <Dropzone
            onDrop={handleDrop}
            multiple={false}
            maxSize={5e6}
            accept=".css"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ outline: "none" }}>
                <input {...getInputProps()} />
                <FileUpload />
                &ensp;Upload CSS file
              </div>
            )}
          </Dropzone>
        </MenuItem>
        <MenuItem style={{ fontSize: 12, color: "#1641DB" }} onClick={reset}>
          <FileRestore />
          &ensp;Restore to default
        </MenuItem>
      </Menu>
    </div>
  );
};

export default GeneralSetting;

const getBoolean = (value) => {
  if (!value) return false;
  return value === "true" || value === true ? true : false;
};
