import {
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@material-ui/core";
import React from "react";
import { rowFlexAlignCenter } from "../../styles/common";
import PaidTab from "../common/PaidTab";

const useStyles = makeStyles((theme) => ({
  select: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px !important",
  },
  selectMenu: (props) => ({
    height: `${props.customHeight || "auto"} !important`,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
}));

const Select = ({
  label = "",
  labelPlacement = "start",
  labelFontWeight = 600,
  helperText = "",
  imgClass,
  labelStyles = {},
  errorElm = true,
  customHeight,
  ...props
}) => {
  const classes = useStyles({ customHeight });
  const error = props.error;
  delete props.error;

  return (
    <div className={labelPlacement === "start" ? rowFlexAlignCenter : ""}>
      {label && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: labelFontWeight,
            minWidth:
              labelPlacement === "start"
                ? props.mainLabelWidth
                  ? props.mainLabelWidth
                  : props.labelWidth
                  ? props.labelWidth
                  : 110
                : 0,
            maxWidth: labelPlacement === "start" ? 110 : "auto",
            marginRight: labelPlacement === "start" ? 8 : 0,
            marginTop: labelPlacement === "start" ? -18 : 0,
            marginBottom: labelPlacement === "top" ? 8 : 0,
            ...labelStyles,
          }}
        >
          {label}
        </Typography>
      )}
      <div
        style={{
          width: props.width ? props.width : "100%",
          ...(!props.width && { flexGrow: 1 }),
        }}
      >
        <MuiSelect
          color="secondary"
          variant={props?.variant ?? "outlined"}
          error={!!error}
          fullWidth
          classes={{ select: classes.select, selectMenu: classes.selectMenu }}
          {...props}
        >
          {props.placeholder && (
            <MenuItem
              key="none"
              value="none"
              style={{
                fontSize: 12,
                color: "#999999",
              }}
            >
              {props.placeholder}
            </MenuItem>
          )}
          {props.options &&
            props.options.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                style={{
                  fontSize: 12,
                  color: "#1a1a1a",
                  ...(props.indent && item.indent ? { marginLeft: 16 } : {}),
                }}
              >
                {item.img && (
                  <div>
                    <img src={item.img} alt={item.title} className={imgClass} />
                  </div>
                )}
                {item.icon && item.icon}
                {item.title} {item.plan ? <PaidTab plan={item.plan} /> : <></>}
              </MenuItem>
            ))}
        </MuiSelect>
        {helperText && (
          <Typography style={{ color: "#666666", fontSize: 12, margin: 2 }}>
            {helperText}
          </Typography>
        )}
        {errorElm && (
          <Typography
            style={{ color: error ? "#F44336" : "#FFFFFF", fontSize: 12 }}
          >
            {error ? error : "&nbsp;"}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Select;
