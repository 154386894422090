import React, { useEffect, useState } from "react";
import { NoPicIcon } from "../../../../../../../icons";
import { CancelOutlined } from "@material-ui/icons";
import {
  cardImage,
  cardImageFallback,
  cardStyle,
} from "../../../../../../coupons/Components/CouponForm/formStyles";
import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress, Grid } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getStoreIdFromUrl } from "../../../../../../../utils/storeUtil";

export const SelectedItem = ({ onRemove, item, setItem, setProducts }) => {
  const [editproducts, setEditProducts] = useState([]);
  const { planId } = useParams();
  const [currentState, setCurrentState] = useState("LOADING");
  const [isEditing, setIsEditing] = useState(false);
  const storeId = getStoreIdFromUrl();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchMoreData();
  }, []);

  const getProducts = async (page = 0, size = 5) => {
    const accessToken = localStorage.getItem("access_token");
    const apiUrl = `${process.env.REACT_APP_API}/api/v1/business/store/${storeId}/subscription-plan`;
    const url = `${apiUrl}/${planId}/products?page=${page}&size=${size}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const fetchMoreData = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    if (planId) {
      try {
        const pageNumber =
          editproducts.length > 0 ? Math.ceil(editproducts.length / 5) : 0;
        const data = await getProducts(pageNumber, 5);
        if (data && data.content && data.content.length > 0) {
          setEditProducts((prevProducts) => [...prevProducts, ...data.content]);
          if (data.content.length < 5) {
            setHasMore(false);
            setCurrentState("ALL_LOADED");
          } else {
            setCurrentState("LOADED");
          }
        } else {
          setHasMore(false);
          setCurrentState(
            editproducts.length === 0 ? "NO_PRODUCTS" : "ALL_LOADED"
          );
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setCurrentState("ERROR_LOADING");
      }
    } else {
      setCurrentState("NO_PLAN_ID");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (editproducts.length > 0) {
      setIsEditing(true);
      const newSelectedProducts = editproducts.map((product) => ({
        ssku: product.ssku,
        sku: product.sku,
      }));
      setProducts((prevFormData) => ({
        ...prevFormData,
        products: newSelectedProducts,
      }));
      setItem({ product: [...editproducts] });
    }
  }, [editproducts, setItem, setProducts]);

  if (currentState === "LOADING") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "blue",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const ItemCard = ({ item }) => (
    <div
      className={cardStyle}
      style={{ display: "flex", alignItems: "center" }}
      key={item.sku || item.prod_sku}
    >
      {item.thumbnail ? (
        <img
          src={item.thumbnail}
          alt={item.display_name}
          className={cardImage}
        />
      ) : (
        <div className={cardImageFallback}>
          <NoPicIcon />
        </div>
      )}
      <div style={{ flexGrow: 1, margin: "0px 8px" }}>
        <p
          style={{
            marginBottom: "0px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#1A1A1A",
          }}
        >
          {item.display_name}
        </p>
        <p
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#666666",
            marginTop: "4px",
          }}
        >
          ₹ {item.sale_price}
        </p>
      </div>
      <CancelOutlined
        htmlColor="#1614DB"
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={() => onRemove(item)}
      />
    </div>
  );
  return (
    <>
      {(currentState === "LOADED" ||
        currentState === "ALL_LOADED" ||
        item.product.length > 0) && (
        <div>
          {isEditing ? (
            <div
              style={{
                maxHeight: "250px",
                overflowY: "scroll",
                borderRadius: "4px",
              }}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                loader={
                  <div style={{ marginTop: 20, textAlign: "center" }} key={0}>
                    <CircularProgress fontSize="small" color="blue" />
                  </div>
                }
              >
                {item.product.map((product) => (
                  <Grid item xs={12} key={product.sku}>
                    <ItemCard item={product} onRemove={onRemove} />
                  </Grid>
                ))}
              </InfiniteScroll>
            </div>
          ) : (
            <div
              style={{
                maxHeight: "250px",
                overflowY: "scroll",
                borderRadius: "4px",
              }}
            >
              {item?.product.map((product) => (
                <ItemCard
                  key={product.sku || product.prod_sku}
                  item={product}
                  onRemove={onRemove}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
