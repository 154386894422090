import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubPlans,
  clearSubPlans,
  setSubPlansPerPage,
  setSubPlansInputKeyword,
  setSubPlansSearchKeyword,
  setSubPlansCurrentPage,
  setSubPlansMaxPageSoFar,
  setSuccessToast,
  deleteSubPlan,
} from "../../../redux/subscription/plans/planActions";
import {
  SUB_PLANS_PER_PAGE,
  DEFAULT_SUB_PLANS_PER_PAGE,
  SUB_PLAN_KEY,
  DEFAULT_PRODUCTS_PER_PAGE,
} from "../../../utils/utils";
import Paginator from "../../../components/Paginator/Paginator";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import Toast from "../../../components/Layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../../styles/common";
import PageLoader from "../../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { useHistory } from "react-router-dom";
import NoRecord from "../../../components/invoiceorder/NoRecord";
import NoSubscriptionFigma from "../../../icons/NoSubscriptionFigma";
import SolidButton from "../../../components/buttons/SolidButton";
import { DeleteIcon, EditIcon } from "../../../icons";
import DeleteModal from "../../../components/common/DeleteModal";
import { handleFetchSuperProduct } from "../../../redux/subscription/superPorduct/superProdActions";

const Contract = () => {
  const classes = useStyles();
  const history = useHistory();
  const subPlanState = useSelector((state) => state.subPlans);
  const state = useSelector((state) => state);
  const superProdState = useSelector((state) => state.superProduct);
  const shortBy = superProdState.shortBy;
  const productsPerPage =
    state.superProduct.productsPerPage || DEFAULT_PRODUCTS_PER_PAGE;
  const subPlans = subPlanState.subPlans;
  const numberOfPages = subPlanState.numberOfPages;
  const subPlansPerPage = subPlanState.subPlansPerPage;
  const currentPage = subPlanState.currentPage;
  const maxPageSoFar = subPlanState.maxPageSoFar;
  const searchKeyword = subPlanState.searchKeyword;
  const inputKeyword = subPlanState.inputKeyword;
  const successToast = subPlanState.successToast;
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [toast, setToast] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const onChangeSubPlansPerPage = (updatedSubPlansPerPage) => {
    localStorage.setItem(SUB_PLAN_KEY, updatedSubPlansPerPage);
    dispatch(clearSubPlans());
    dispatch(setSubPlansCurrentPage(0));
    dispatch(setSubPlansMaxPageSoFar(0));
    dispatch(setSubPlansPerPage(updatedSubPlansPerPage));
    dispatch(fetchSubPlans(0, searchKeyword, updatedSubPlansPerPage));
  };
  let startIndex = currentPage * subPlansPerPage;
  let endIndex =
    startIndex + subPlansPerPage < subPlans.length
      ? startIndex + subPlansPerPage
      : subPlans.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  useEffect(() => {
    if (subPlans.length === 0 || subPlanState.pageDirty) {
      dispatch(fetchSubPlans());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (!subPlansPerPage) {
      dispatch(setSubPlansPerPage(SUB_PLANS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [subPlansPerPage]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(fetchSubPlans(currentPage + 1, searchKeyword, subPlansPerPage));
      dispatch(
        setSubPlansMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setSubPlansCurrentPage(currentPage + 1));
    } else {
      dispatch(setSubPlansCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setSubPlansCurrentPage(currentPage - 1));
  };

  const handleEditClick = (tableitem) => {
    history.push(`./plans/${tableitem.id}`);
  };
  const handleDeleteClick = (item) => {
    const hasContracts = item.frequencies.some(
      (frequency) => frequency.can_delete === false
    );
    if (hasContracts) {
      setDeleteMessage(true);
    }
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };
  const onCancel = () => {
    setOpenDeleteModal(false);
    setSelectedItem(null);
  };

  const onConfirmDelete = async () => {
    if (selectedItem) {
      try {
        await dispatch(deleteSubPlan(selectedItem.id, subPlansPerPage));
        setOpenDeleteModal(false);
        setSelectedItem(null);
        dispatch(
          handleFetchSuperProduct(
            searchKeyword,
            shortBy,
            currentPage,
            productsPerPage
          )
        );
      } catch (error) {
        setMessage(
          error.response?.data?.message || "Failed to delete the plan."
        );
        setSeverity("error");
        setToast(true);
      }
    }
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setSubPlansMaxPageSoFar(0));
      dispatch(setSubPlansCurrentPage(0));
      dispatch(fetchSubPlans(0, keyword, subPlansPerPage));
    }
  };

  if (successToast) {
    setToast(true);
    dispatch(setSuccessToast(false));
  }

  if (subPlanState.progress && subPlans.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Plans" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Plans" }]} />
              <SolidButton
                onClick={() => {
                  history.push("./plans/create");
                }}
                style={{ width: 185 }}
              >
                + Add Plan
              </SolidButton>
            </div>
            {subPlans.length === 0 && searchKeyword === "" ? (
              <NoRecord
                text="Start Creating Subscription Plan for you Store"
                image={<NoSubscriptionFigma />}
                // bottomText="No subscriptions have been added so far"
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.statusRow}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search by subscrion plan name"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) => {
                          dispatch(setSubPlansInputKeyword(e.target.value));
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(setSubPlansSearchKeyword(inputKeyword));
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setSubPlansInputKeyword(""));
                            dispatch(setSubPlansSearchKeyword(""));
                            dispatch(setSubPlansMaxPageSoFar(0));
                            dispatch(setSubPlansCurrentPage(0));
                            dispatch(fetchSubPlans(0, null, subPlansPerPage));
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setSubPlansSearchKeyword(""));
                            dispatch(setSubPlansInputKeyword(""));
                            dispatch(setSubPlansMaxPageSoFar(0));
                            dispatch(setSubPlansCurrentPage(0));
                            dispatch(fetchSubPlans(0, null, subPlansPerPage));
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "8%" }}>
                            Plan Title
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Plan Display Name
                          </TableCell>
                          <TableCell style={{ width: "21%" }}>
                            Products
                          </TableCell>
                          <TableCell style={{ width: "18%" }}>
                            Interval
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>
                            Action Type
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {subPlans
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % subPlansPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell align="left">
                                {tableitem.display_title}
                              </TableCell>
                              <TableCell align="left">
                                {tableitem.display_name}
                              </TableCell>

                              <TableCell align="left">
                                {tableitem.products &&
                                tableitem.products.length > 0 ? (
                                  <>
                                    {tableitem.products
                                      .slice(0, 3)
                                      .map((item, idx) => (
                                        <span
                                          key={idx}
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block", // Ensures each product is on a new line
                                          }}
                                        >
                                          {item.display_name}
                                          {idx === 2 &&
                                            tableitem.product_count > 3 &&
                                            " ..."}{" "}
                                          {/* Add ... after the third product */}
                                        </span>
                                      ))}
                                  </>
                                ) : (
                                  "No product added"
                                )}
                              </TableCell>

                              <TableCell align="left">
                                {tableitem?.frequencies.map((item, idx) => {
                                  const capitalizedDiscountType =
                                    item.interval.charAt(0).toUpperCase() +
                                    item.interval.slice(1).toLowerCase();
                                  const updatedInterval =
                                    item.repeat_every === 2
                                      ? "Alternate Days"
                                      : item.repeat_every === 3
                                      ? "Every 3 Days"
                                      : capitalizedDiscountType;
                                  return (
                                    updatedInterval +
                                    (idx !== tableitem.frequencies.length - 1
                                      ? ", "
                                      : "")
                                  );
                                })}
                              </TableCell>

                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    style={{ padding: "0px" }}
                                    onClick={() => handleEditClick(tableitem)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    style={{
                                      padding: "0px",
                                      marginLeft: "15px",
                                    }}
                                    onClick={() => handleDeleteClick(tableitem)}
                                  >
                                    <DeleteIcon color="red" />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {subPlanState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 69 * subPlansPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {subPlans.length === 0 && (
                      <div
                        style={{
                          height: 69 * subPlansPerPage,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Customers Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeSubPlansPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_SUB_PLANS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={subPlanState.numberOfElements}
                  recordsPerPage={subPlansPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Toast
        open={toast}
        close={() => setToast(false)}
        message={subPlanState.message || message}
        severity={severity}
      />
      {openDeleteModal && (
        <DeleteModal
          item={selectedItem.display_name}
          deleteMessage={
            deleteMessage
              ? "Are you sure you want to delete this plan? There are contracts associated with it."
              : ""
          }
          onCancel={onCancel}
          style={{
            color: "red",
            backgroundColor: "#fff2e2",
            padding: "5px 8px",
            borderRadius: "8px",
          }}
          onConfirmDelete={onConfirmDelete}
          isDeleting={subPlanState.isDeleting}
        />
      )}

      {/* <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={BUSINESS_APP_PRO}
        subtxt="Start offering Reward Points to your Loyal Customers and Grow Business"
      /> */}
    </Fragment>
  );
};
export default Contract;
