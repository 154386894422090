import React from "react";
import { Typography, Box, InputAdornment } from "@material-ui/core";
import useStyles from "../../../styles";
import Controls from "../../../../../../components/controls/Controls";

const PlanTitle = ({ formData, onChange, errors, onBlur }) => {
  const classes = useStyles();
  const handleBlur = (e) => {
    const { name, value } = e.target;
    onBlur(name, value);
  };
  return (
    <div>
      <Typography className={classes.heading}>Plan Details</Typography>
      <Box>
        <Controls.Input
          type="text"
          name="display_title"
          label="Plan Title"
          value={formData.display_title}
          onChange={onChange}
          onBlur={handleBlur}
          error={errors.display_title}
          helperSubText=" This will not be displayed to the customer."
          labelFontWeight={400}
          labelPlacement="top"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  position: "relative",
                  top: "-40px",
                  fontSize: "14px",
                  right: "-9px",
                }}
              >
                {formData.display_title?.length}/{30}
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 30 }}
        />
        <Controls.Input
          type="text"
          name="display_name"
          label="Plan Display Name*"
          value={formData.display_name}
          onChange={onChange}
          error={errors.display_name}
          helperSubText=" This will be displayed to customer"
          onBlur={handleBlur}
          labelFontWeight={400}
          labelPlacement="top"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  position: "relative",
                  top: "-40px",
                  fontSize: "14px",
                  right: "-9px",
                }}
              >
                {formData.display_name.length}/{30}
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 30 }}
        />
      </Box>
    </div>
  );
};

export default PlanTitle;
