import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
  Checkbox,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchContracts,
  clearContracts,
  setContractsPerPage,
  setContractsInputKeyword,
  setContractsSearchKeyword,
  setContractsCurrentPage,
  setContractsMaxPageSoFar,
  setSuccessToast,
  setContractsStateFilter,
} from "../../../redux/subscription/contract/contractActions";
import {
  CONTRACTS_PER_PAGE,
  DEFAULT_CONTRACTS_PER_PAGE,
  CONTRACT_KEY,
} from "../../../utils/utils";
import Paginator from "../../../components/Paginator/Paginator";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import Toast from "../../../components/Layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../../styles/common";
import PageLoader from "../../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { useHistory } from "react-router-dom";
import NoRecord from "../../../components/invoiceorder/NoRecord";
import NoSubscriptionFigma from "../../../icons/NoSubscriptionFigma";
import ExportCta from "./components/ExportCta";
import StateElement from "./components/StateElement";
import StatusDotIcon from "../../../icons/StatusDotIcon";

const Contract = () => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const contractState = useSelector((state) => state.contracts);
  const contracts = contractState.contracts;
  const numberOfPages = contractState.numberOfPages;
  const contractsPerPage = contractState.contractsPerPage;
  const currentPage = contractState.currentPage;
  const maxPageSoFar = contractState.maxPageSoFar;
  const searchKeyword = contractState.searchKeyword;
  const inputKeyword = contractState.inputKeyword;
  const successToast = contractState.successToast;
  const stateFilter = contractState.state;
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [toast, setToast] = useState(false);
  const [selectedState, setSelectedState] = useState("All");

  const states = ["All", "Active", "Paused", "Cancelled"];

  const onChangeContractsPerPage = (updatedContractsPerPage) => {
    localStorage.setItem(CONTRACT_KEY, updatedContractsPerPage);
    dispatch(clearContracts());
    dispatch(setContractsCurrentPage(0));
    dispatch(setContractsMaxPageSoFar(0));
    dispatch(setContractsPerPage(updatedContractsPerPage));
    dispatch(
      fetchContracts(0, searchKeyword, stateFilter, updatedContractsPerPage)
    );
  };
  let startIndex = currentPage * contractsPerPage;
  let endIndex =
    startIndex + contractsPerPage < contracts.length
      ? startIndex + contractsPerPage
      : contracts.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  useEffect(() => {
    if ((contracts.length === 0 && !stateFilter) || contractState.pageDirty) {
      dispatch(setContractsStateFilter("All"));
      dispatch(fetchContracts());
    }
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (!contractsPerPage) {
      dispatch(setContractsPerPage(CONTRACTS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [contractsPerPage]);

  const statusToText = (status) => {
    if (status === "ACTIVE") return { text: "Active", fill: "#72C472" };
    if (status === "PAUSED") return { text: "Paused", fill: "#FFB800" };
    if (status === "CANCELLED") return { text: "Cancelled", fill: "#FF0000" };
    return { text: "-", fill: "white" };
  };
  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchContracts(
          currentPage + 1,
          searchKeyword,
          stateFilter,
          contractsPerPage
        )
      );
      dispatch(
        setContractsMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setContractsCurrentPage(currentPage + 1));
    } else {
      dispatch(setContractsCurrentPage(currentPage + 1));
    }
  };

  const handleChangeStateSelector = (newState) => {
    setSelectedState(newState);
    dispatch(
      fetchContracts(currentPage, searchKeyword, newState, contractsPerPage)
    );
  };
  const onPrevPage = () => {
    dispatch(setContractsCurrentPage(currentPage - 1));
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setContractsMaxPageSoFar(0));
      dispatch(setContractsCurrentPage(0));
      dispatch(setContractsStateFilter("All"));
      dispatch(fetchContracts(0, keyword, "All", contractsPerPage));
    }
  };

  if (successToast) {
    setToast(true);
    dispatch(setSuccessToast(false));
  }

  if (contractState.progress && contracts.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Contract" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Contract" }]} />
              <ExportCta />
            </div>
            {contracts.length === 0 &&
            searchKeyword === "" &&
            stateFilter === "All" ? (
              <NoRecord
                text="No subscriptions have been added so far."
                image={<NoSubscriptionFigma />}
                // bottomText="No subscriptions have been added so far"
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search by name or phone number or affiliate id"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) => {
                          dispatch(setContractsInputKeyword(e.target.value));
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(setContractsSearchKeyword(inputKeyword));
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setContractsInputKeyword(""));
                            dispatch(setContractsSearchKeyword(""));
                            dispatch(setContractsMaxPageSoFar(0));
                            dispatch(setContractsCurrentPage(0));
                            dispatch(
                              fetchContracts(0, null, "All", contractsPerPage)
                            );
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setContractsSearchKeyword(""));
                            dispatch(setContractsInputKeyword(""));
                            dispatch(setContractsMaxPageSoFar(0));
                            dispatch(setContractsCurrentPage(0));
                            dispatch(
                              fetchContracts(0, null, "All", contractsPerPage)
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={classes.statusRow}>
                    {states.map((state) => (
                      <StateElement
                        key={state}
                        stateSelector={selectedState}
                        selector={handleChangeStateSelector}
                        state={state}
                        numElements={contractState.numberOfElements}
                      />
                    ))}
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "8%" }}>
                            Contract#
                          </TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Customer Name
                          </TableCell>
                          <TableCell style={{ width: "30%" }}>
                            Product
                          </TableCell>
                          <TableCell style={{ width: "8%" }}>
                            Price (₹)
                          </TableCell>
                          <TableCell style={{ width: "18%" }}>
                            Delivery Interval
                          </TableCell>
                          <TableCell style={{ width: "14%" }}>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {contracts
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % contractsPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <span
                                  onClick={() => {
                                    history.push(`./contract/${tableitem.id}`);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#1641DB",
                                  }}
                                >
                                  CTR - {tableitem.id}
                                </span>
                              </TableCell>
                              <TableCell>{tableitem?.customer?.name}</TableCell>
                              <TableCell
                                style={{ textTransform: "capitalize" }}
                              >
                                {tableitem.product_name}
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {tableitem.discounted_price}
                              </TableCell>
                              <TableCell>
                                {tableitem.subscription_frequency.interval
                                  .charAt(0)
                                  .toUpperCase() +
                                  tableitem.subscription_frequency.interval
                                    .slice(1)
                                    .toLowerCase()}
                              </TableCell>

                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StatusDotIcon
                                    fill={statusToText(tableitem.status).fill}
                                  />
                                  &nbsp;&nbsp;
                                  {statusToText(tableitem.status).text}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {contractState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 69 * contractsPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {contracts.length === 0 && (
                      <div
                        style={{
                          height: 69 * contractsPerPage,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Customers Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeContractsPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_CONTRACTS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={contractState.numberOfElements}
                  recordsPerPage={contractsPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Toast
        open={toast}
        close={() => setToast(false)}
        message={contractState.message}
      />

      {/* <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={BUSINESS_APP_PRO}
        subtxt="Start offering Reward Points to your Loyal Customers and Grow Business"
      /> */}
    </Fragment>
  );
};
export default Contract;
