import React from "react";
import { rowFlexAlignCenter } from "../../../../../styles/common";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
const CustomerInfo = ({ contractDetails }) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography className={classes.heading} style={{ marginBottom: 0 }}>
          Customer Details
        </Typography>
        <div style={{ marginTop: "17px" }}>
          <div className={rowFlexAlignCenter} style={{ marginBottom: "8px" }}>
            <AccountCircleOutlinedIcon color="secondary" />
            <Typography
              style={{
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {contractDetails?.customer?.name}
            </Typography>
          </div>

          <div className={rowFlexAlignCenter} style={{ marginBottom: "8px" }}>
            <PhoneOutlinedIcon color="secondary" />
            <Typography
              style={{
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              {contractDetails?.customer?.mobile}
            </Typography>
          </div>

          <div className={rowFlexAlignCenter} style={{ marginBottom: "8px" }}>
            <LocationOnOutlinedIcon color="secondary" />
            <Typography
              style={{
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              {contractDetails?.customer?.shipping_address?.street1}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
