import axios from "axios";
import { getDefaultStore } from "../../../utils/storeUtil";

export const getContractById = async (contractId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/subscriptions/contracts/${contractId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};

export const getUpcomingActivities = async (contractId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/subscriptions/contracts/${contractId}/activities/upcoming?previous=0`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};

export const addSkipResume = async (date, type, contractId) => {
  const toggleableValue = type === "Skip" ? "skip" : "resume";
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/business/store/${store.store_id}/subscriptions/contracts/${contractId}/activities/${date}/${toggleableValue}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};
