import { Box, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../../../styles";
import { DeleteIcon } from "../../../../../../icons";
import Controls from "../../../../../../components/controls/Controls";
import Toast from "../../../../../../components/Layout/Toast";

const DeliveryFrequency = ({
  formData,
  setFormData,
  onChange,
  onBlur,
  errors,
}) => {
  const options = [
    { id: "FLAT_RATE", title: "Flat Rate" },
    { id: "PERCENTAGE", title: "Percentage" },
    { id: "AMOUNT_OFF", title: "Amount Off" },
  ];
  const interval = [
    { id: "DAILY", title: "Daily", frequecy: 1 },
    { id: "ALTERNATE_DAYS", title: "Alternate Days", frequecy: 2 },
    { id: "EVERY_3_DAYS", title: "Every 3 Days ", frequecy: 3 },
    { id: "WEEKLY", title: "Weekly", frequecy: 1 },
    { id: "MONTHLY", title: "Monthly", frequecy: 1 },
    { id: "YEARLY", title: "Yearly", frequecy: 1 },
  ];

  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (formData.frequencies) {
      setFields(formData.frequencies);
    }
  }, [formData.frequencies]);

  const handleFieldChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "interval") {
      let selectedValue = value;

      const selectedInterval = interval.find(
        (item) => item.id === selectedValue
      );
      const repeatEvery = selectedInterval ? selectedInterval.frequecy : "1";

      const updatedFields = fields.map((field, i) =>
        i === index
          ? { ...field, [name]: selectedValue, repeat_every: repeatEvery }
          : field
      );

      setFields(updatedFields);
      setFormData((prev) => ({
        ...prev,
        frequencies: updatedFields,
      }));
    } else {
      const parsedValue =
        name === "repeat_every" || name === "discount_value"
          ? parseInt(value, 10) || 0
          : value;

      onChange(e, index, "frequencies");

      const updatedFields = fields.map((field, i) =>
        i === index ? { ...field, [name]: parsedValue } : field
      );

      setFields(updatedFields);
      setFormData((prev) => ({
        ...prev,
        frequencies: updatedFields,
      }));
      onBlur(name, parsedValue);
    }
  };

  const handleAddOption = () => {
    const newField = {
      interval: "",
      repeat_every: null,
      discount_type: "",
      discount_value: null,
    };
    setFields((prev) => [...prev, newField]);
    setFormData((prev) => ({
      ...prev,
      frequencies: [...prev.frequencies, newField],
    }));
  };

  const handleRemoveOption = (index) => {
    const targetField = fields[index];
    if (targetField.can_delete === false) {
      setMessage("Cannot delete Contract exists!");
      return;
    }
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    setFormData((prev) => ({
      ...prev,
      frequencies: updatedFields,
    }));
  };

  return (
    <div>
      <Typography className={classes.heading}>Discount & Delivery </Typography>
      {fields.map((field, index) => (
        <Box
          key={index}
          style={{
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div style={{ width: "35%" }}>
            <Controls.Select
              name="interval"
              label="Interval"
              labelFontWeight={400}
              placeholder="Select"
              customHeight="0px"
              labelPlacement="top"
              options={interval.map((option) => ({
                id: option.id,
                title: option.title,
              }))}
              value={field.interval || "none"}
              onChange={(e) => handleFieldChange(index, e)}
              error={errors[`frequency_${index}_interval`]}
              onBlur={(e) =>
                onBlur(`frequency_${index}_interval`, e.target.value, index)
              }
            />
          </div>
          {/* <div style={{ width: "12%" }}>
            <Controls.Input
              type="number"
              name="repeat_every"
              label="Frequency"
              labelFontWeight={400}
              labelPlacement="top"
              value={field.repeat_every || "none"}
              onChange={(e) => handleFieldChange(index, e)}
              error={errors[`frequency_${index}_frequency`]}
              onBlur={(e) =>
                onBlur(`frequency_${index}_frequency`, e.target.value, index)
              }
            />
          </div> */}

          <div style={{ width: "35%" }}>
            <Controls.Select
              name="discount_type"
              label="Discount Type"
              placeholder="Select"
              labelPlacement="top"
              customHeight="0px"
              labelFontWeight={500}
              options={options.map((option) => ({
                id: option.id,
                title: option.title,
              }))}
              value={field.discount_type || "none"}
              onChange={(e) => handleFieldChange(index, e)}
              error={errors[`frequency_${index}_discount_type`]}
              onBlur={(e) =>
                onBlur(
                  `frequency_${index}_discount_type`,
                  e.target.value,
                  index
                )
              }
            />
          </div>
          <div style={{ width: "20%" }}>
            <Controls.Input
              type="text"
              name="discount_value"
              label="Discount"
              labelFontWeight={400}
              labelPlacement="top"
              startAdornment={
                field.discount_type === "FLAT_RATE" ||
                field.discount_type === "AMOUNT_OFF" ? (
                  <span className={classes.rupee}>₹</span>
                ) : null
              }
              endAdornment={
                field.discount_type === "PERCENTAGE" ? (
                  <span className={classes.rupee}>%</span>
                ) : null
              }
              value={field.discount_value}
              onChange={(e) => handleFieldChange(index, e)}
              error={!!errors[`frequency_${index}_discount_value`]}
              helperText={errors[`frequency_${index}_discount_value`]}
              onBlur={(e) =>
                onBlur(
                  `frequency_${index}_discount_value`,
                  e.target.value,
                  index
                )
              }
            />
          </div>
          <Box style={{ position: "relative", top: "6px", margin: "auto 0" }}>
            <DeleteIcon
              color="red"
              onClick={
                fields.length > 1 ? () => handleRemoveOption(index) : null
              }
              style={{
                cursor: fields.length > 1 ? "pointer" : "not-allowed",
                opacity: fields.length > 1 ? 1 : 0.6,
              }}
            />
          </Box>
        </Box>
      ))}
      <Box>
        <Button className={classes.addOptionButton} onClick={handleAddOption}>
          + Add Option
        </Button>
      </Box>
      <Toast
        open={message}
        message={message}
        severity="error"
        close={() => setMessage(null)}
      />
    </div>
  );
};

export default DeliveryFrequency;
