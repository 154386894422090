import { Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CombinedButtons from "../buttons/CombinedButtons";
import CloseButton from "../buttons/CloseButton";
import { rowFlexJustifyBetween } from "../../styles/common";
import { isWindows } from "react-device-detect";
const FormModal = ({
  width = 512,
  heading = "",
  onClose = () => {},
  onSave = () => {},
  disableSave = false,
  loading = false,
  btnTxt = "Save",
  outlinedBtnText = "Cancel",
  open = false,
  children,
  hideCancel = false,
  hideSaveButton = false,
  startIcon,
  maxHeight = 88,
  headingStyle = {},
  bottomLeftAdornment,
  bottomCenterAdornment,
  bottomRightAdornment,
  isDelete = false,
  hideCombinedButton = false,
  btnStyle = {},
  hideCloseIcon = false,
  padding = "0 25px",
  overlay = true,
  minHeight,
  hideBottomStickyButton = false,
  closeOnOutsideClick = false,
  marginBottom = 20,
  scrollFromBelowTop = false, // New flag
  calculateMaxHeight,
}) => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={closeOnOutsideClick ? onClose : null}
      BackdropProps={{
        onClick: closeOnOutsideClick ? onClose : null,
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onClick={closeOnOutsideClick ? (e) => e.stopPropagation() : null}
        style={{
          padding: padding,
          borderRadius: 12,
          position: "absolute",
          background: "#ffffff",
          width,
          maxWidth: `${88 * scale}vw`,
          maxHeight: `${maxHeight * scale}vh`,
          minHeight: minHeight && `${minHeight * scale}vh`,
          outline: 0,
          overflow: overlay ? "overlay" : "",
        }}
      >
        <div
          className={rowFlexJustifyBetween}
          style={{
            marginBottom: marginBottom,
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: 25,
            zIndex: 2,
            background: "#ffffff",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 20,
              lineHeight: "32px",
              color: "#1A1A1A",
              ...headingStyle,
            }}
          >
            {heading}
          </Typography>
          {!hideCloseIcon && (
            <CloseButton
              onClick={onClose}
              style={{ minWidth: 0, padding: 6 }}
              iconStyle={{ width: 16, height: 16 }}
            />
          )}
        </div>
        {children}
        {!hideBottomStickyButton && (
          <div
            style={{
              height: 84,
              position: "sticky",
              background: "#ffffff",
              zIndex: 2,
              bottom: 0,
              marginTop: 24,
            }}
          >
            {bottomLeftAdornment && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  padding: "25px 0px",
                }}
              >
                {bottomLeftAdornment}
              </div>
            )}
            {bottomCenterAdornment && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  paddingTop: 12,
                }}
              >
                {bottomCenterAdornment}
              </div>
            )}
            {bottomRightAdornment && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: "25px 0px",
                }}
              >
                {bottomRightAdornment}
              </div>
            )}
            {!hideCombinedButton && !hideSaveButton && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: "25px 0px",
                }}
              >
                <CombinedButtons
                  solidBtnText={btnTxt}
                  outlinedBtnText={outlinedBtnText}
                  outlinedBtnAction={onClose}
                  solidBtnAction={onSave}
                  loading={loading}
                  disabled={disableSave}
                  hideOutlineBtn={hideCancel}
                  startIcon={startIcon}
                  isDelete={isDelete}
                  style={{ ...btnStyle, height: 44 }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FormModal;
