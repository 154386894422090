import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import { rowFlexJustifyBetween } from "../../../../../styles/common";
import { useParams } from "react-router-dom";
import { addSkipResume, getUpcomingActivities } from "../../contractUtils";
import Toast from "../../../../../components/Layout/Toast";

const UpcomingOrders = () => {
  const classes = useStyles();
  const params = useParams();

  const [activities, setActivities] = useState([]);
  const [message, setMessage] = useState("");
  const [loadingActivityId, setLoadingActivityId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    if (params.contractId) {
      fetchActivities();
    }
  }, [params.contractId]);

  const fetchActivities = async () => {
    try {
      const res = await getUpcomingActivities(params.contractId);
      setActivities(res);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const handleSkipResume = async (activity) => {
    const type = activity.skipped ? "Resume" : "Skip";
    try {
      setLoadingActivityId(activity.id);
      const response = await addSkipResume(
        activity.id,
        type,
        params.contractId
      );
      await fetchActivities();
      if (response.status === 200) {
        setMessage(`${activity.id} ${type} successfully`);
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred");
    } finally {
      setLoadingActivityId(null);
    }
  };

  return (
    <Box>
      <Typography className={classes.heading} style={{ marginBottom: 0 }}>
        Upcoming Deliveries
      </Typography>
      <Typography className={classes.subHeading} style={{ marginTop: 8 }}>
        Manage customers upcoming orders
      </Typography>

      {activities.map((activity, idx) => (
        <div key={activity.id}>
          <div className={rowFlexJustifyBetween} style={{ marginTop: 14 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                className={classes.heading}
                style={{ fontWeight: 400 }}
              >
                {activity.activity_at}
              </Typography>
              {activity.skipped && (
                <Typography className={classes.skippedHeading}>
                  Skipped
                </Typography>
              )}
            </div>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Button
                className={classes.skippedButton}
                onClick={() => handleSkipResume(activity)}
                disabled={loadingActivityId === activity.id}
                variant="text"
              >
                {loadingActivityId === activity.id ? (
                  <CircularProgress color="inherit" size={20} />
                ) : activity.skipped ? (
                  "Resume"
                ) : (
                  "Skip"
                )}
              </Button>
            </div>
          </div>
          {idx !== activities.length - 1 && (
            <Divider style={{ margin: "14px 0px" }} />
          )}
        </div>
      ))}

      <Toast
        message={message}
        severity="success"
        open={Boolean(message)}
        close={() => setMessage(null)}
      />
      <Toast
        message={errorMessage}
        severity="error"
        open={Boolean(errorMessage)}
        close={() => setErrorMessage(null)}
      />
    </Box>
  );
};

export default UpcomingOrders;
