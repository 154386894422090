import { Link, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles(() => ({
  subHeading: {
    fontWeight: 500,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 0,
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
}));

const OrderAttributeRow = ({ label, value, highlight, icon, link }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexBox} style={{ marginTop: 8 }}>
      <Typography className={classes.subHeading}>{label}</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon && <span style={{ marginTop: -1 }}>{icon}&nbsp;</span>}
        {link ? (
          <Link
            style={{
              color: "#1641DB"
            }}
            target="_blank"
            href={link}
          >
            {value}
          </Link>
        ) : (
          <Typography
            className={classes.subHeading}
            style={{
              color: highlight ? "#1A1A1A" : "#666666",
            }}
          >
            {value}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default OrderAttributeRow;
